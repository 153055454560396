@import './common.css';

/* Table style css start here  */
.TableParent .TableheadStyle {
    background-color: white;
}

.HeadCellStyle {
    background-color: var(--background-color);
    color: var(--text-color);
    text-align: center;
}

.TableRowStyle:hover {
    background-color: var(--background-hover);
    text-align: center;
    color: red;
}

.TableParent .textCentre,
.textCentre {
    text-align: center;
}

.TableParent .TableLogo img {
    display: inline;
    height: 46px;
}

.MuiTablePagination-toolbar {
    align-items: baseline;
}

/* Table style css end here  */
/* Report component first table css start here  */
.TableParentSecond {
    background-color: white;
}

.TableParentSecond .FirstTableHead {
    background-color: var(--secondtable-background-clor);
    color: black;
}

.TableParentSecond .FirstTableBody:hover {
    background-color: var(--secondtable-background-hover-clor)
}

/* Report component first table css end  here  */
/* Search bar css start here  */
.css-d3ri6l-MuiStack-root {
    margin-bottom: 15px !important;
}


.css-kli1rf-MuiTypography-root {
    margin-bottom: 15px !important;
}

/* Search bar css end  here  */

/* Report section table css start here  */

.ReportParent .css-1uoh5bq-MuiTableCell-root {
    border-bottom: 2px solid black
}

.ReportHeading {
    background-color: #BDD6EE;
}

.left-side {
    background-color: rgba(31, 79, 123, 255);
    color: white;
    width: 40%;
}

.right-side {
    background-color: rgba(222, 234, 246, 255);
}

.my-custom-table .MuiTableCell-root {
    border: 2px solid black;
}

/* Report section table css end here  */