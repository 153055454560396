  .dropdown-container {
    position: relative;
  }
  
  .dropdown-container select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .dropdown-container::after {
    content: "\25BC";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }
 
  .custom-progress-bar {
    background-color: rgb(142, 177, 201) !important;
  }
  .w-30{
    width: 31%;
  }
 /* mui css */
  .css-vb5riy-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 11.5px 2px !important;
  }
 