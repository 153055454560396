@import './common.css';
/* Form css start here  */

.paperStyle {
    padding: 30px 20px;
    width: 500px;
    margin: 0 auto;
    position: relative;
}

.headerStyle {
    margin: 8px;
}

/* Form css end here  */

/* Textfiled of mui font size change here css start  */

.MuiInputLabel-root {
    font-size: var(--font-size);
}

.form-select {
    font-size: var(--font-size);
}

/* Textfiled of mui font size change here end  start  */

/* Cross icon style start here  */
.CrossIconParent {
    position: absolute;
    top: 10px;
    right: 15px;
    color: red;
}

.CrossIconParent .iconCross {
    cursor: pointer;
    font-size: '18px';
}

/* Cross icon style end  here  */

/* Profile page css start here  */

.profileparent .gradient-custom {
    background: var(--background-color);
}

.profileparent .profileChildFirst {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
    text-align: center;
}

.profileparent .profileChildFirst img {
    width: 70%;
    margin: auto;
}

.profileparent .editparent {
    background-color: var(--background-color);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.editparent .editbuttonChild {
    position: absolute;
    right: 0;
}

/* Profile page css end here  */

.inputTextSize {
    font-size: var(--dynamic-font-size)
}


/* RequestDetails css start here  */

.whatappButton{
    border: none;
}

/* RequestDetails css start here  */




/* Media query start for 425px  start here */

@media screen and (max-width: 426px) {
    .paperStyle {
      width: 315px;
    }
    .css-8oxboj-MuiInputBase-root-MuiTablePagination-select{
        margin-right: 0px;
        margin-left: 0px;
    }

    .row {
        margin-right: calc(-.0 * var(--bs-gutter-x));
        margin-left: calc(-.0 * var(--bs-gutter-x));
    }
    .css-1byr0tz {
        padding-top: 80px;
        padding-bottom: 0px;
    }

  }


  /* Media query start for 425px  end here */

  /* Media query start for 320px  start here */

  @media screen and (max-width: 321px) {
    .paperStyle {
      width: 300px;
    }
  }


  /* Media query start for 320px  end here */


