:root{
    --navbar-background-color : rgba(224,243,255,1);
    --navbar-active-tab-color : #5A5A5A ; 
    --navbar-text-color : #5A5A5A ; 
    --navbar-icon-color : #5A5A5A ; 
    --background-color:  rgba(214, 233, 247, 1);
    --background-hover : rgba(224,243,255,0.5);
    --background-color-button : rgb(87, 140, 173);
    --secondtable-background-clor : rgba(214, 233, 247, 1);
    --secondtable-background-hover-clor : rgba(224,243,255,0.5);
    --font-size: 14px;
    --dynamic-font-size: 15px;
    --text-color: white;
    --text-color : black;
}