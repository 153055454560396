@import '../../assets/css/common.css';

/* main box */

/* complete screen of form builder (Step,sidebar,from) */
.sidebarmainbody {
  position: relative;
}

/* form center box (main form) */
.mainforbuilder {
  width: 750px;
}

/* main form inside div */
.mainFrom {
  background-color: var(--background-color) !important;
  margin: 10px;
  min-height: 200px;
}

.form-container {
  margin-top: 20px;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 20px;
}

/* left-sidebar */

/* side-bar button */
.firstbtncle {
  background-color: var(--background-color) !important;
  cursor: pointer;
}
/* left-sidebar box*/
.sidebarwidth {
  width: 200px;
}
.sticky-sidebar {
  position: fixed;
}

/* select bank drop-sown */
.form-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  background-color: #fff;
}

.form-select:focus {
  border-color: #007bff;
  outline: none;
}

/* input fields */
.form-field input[type='text'],
.form-field input[type='file'],
.form-field input[type='number'],
.form-field select {
  width: 100%;
  padding: 8px;
  margin: 2px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  background-color: #fff;
  transition: border-color 0.2s ease;
}

/* side options (edit,delete,acc) */
.buttonspointer {
  cursor: pointer;
  margin: 1px;
  /* color: rgb(0, 0, 0); */
}
.editButtonspointer{
  color: blue;
}

.deleteButtonspointer{
  color: darkred;
}
.accesbilityButtonspointer{
  color: green;
}
/* input fields outer */
.form-field {
  margin-bottom: 20px;
  opacity: 1;

}

.form-field:hover .corner-icons {
  opacity: 1;
  transform: translateY(-50%);
  transition: opacity 0.2s ease, transform 0.2s ease;
}
/* button background */
.corner-icons {
  position: absolute;
  top: 50%;
  right: 0;
  opacity: 1;
  transform: translateY(-50%);
}

@media (min-width: 1024px) {
  .mainforbuilder {
    /* width: calc(100% - 250px); */
    width: 70%;
  }
}
@media (max-width: 1024px) {
  /* .mainforbuilder {
    width: calc(100% - 250px);
  } */
  .mainforbuilder {
    /* width: calc(100% - 250px); */
    width: 70%;
  }
}
@media (max-width: 991px) {
  .mainforbuilder {
    /* width: calc(100% - 250px); */
    width: 78%;
    padding-left: 40px;
  }
  .sidebarwidth {
    width: 130px;
  }
}
@media(max-width:425px){
  /* .mainforbuilder{
    background: #000;
  } */
  .sidebarwidth {
    width: 72px;
  }
  .firstbtncle{
    /* background: #ac0c0c !important; */
    padding: 0px;

  }
  
}

/* 
@media (min-width: 1024px) {
  .form-container {
    max-width: 1024px; 
  }

  .mainFrom {
    margin: 20px; 
  }

  .sidebarwidth {
    width: 250px; 
  }

  .mainforbuilder {
    width: calc(100% - 250px);
  }

  .sticky-sidebar {
    position: sticky;
    top: 20px; 
  }
}
@media (min-width: 770px) and (max-width: 1012px) {
  .form-container {
    max-width: 900px;
  }

  .mainFrom {
    margin: 15px;
  }

  .sidebarwidth {
    width: 200px; 
  }

  .mainforbuilder {
    width: calc(100% - 200px); 
  }

  .sticky-sidebar {
    position: sticky;
    top: 10px; 
  }
}

@media (max-width: 768px) {
  .form-container {
    max-width: 100%;
    padding: 10px;
  }

  .mainFrom {
    margin: 5px; 
  }

  .sidebarwidth {
    width: 100%; 
  }

  .mainforbuilder {
    width: 100%; 
  }

  .sticky-sidebar {
    position: static;
  }
} */
