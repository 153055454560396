.FormStyle{
    width: 75%;
    padding: 10px;
    padding-left: 3px !important;
    
}

/* Media query start for 425px  start here */

@media screen and (max-width: 321px) {
    .FormStyle {
      width: 100%;
      padding-left: 0px !important;
    }
    .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions{
        margin-left: 0px !important;
    }

    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl { 
        padding-right: calc(var(--bs-gutter-x) * .0);
    }
  }


  /* Media query start for 425px  end here */